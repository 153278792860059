// import firebase from 'firebase/app'
import { upsertForm } from './form'
// import { fireauth, actionCodeSettings } from '../firebase/firebase'
// import { getAuth } from 'firebase/auth'
import { getAuth, signOut, signInWithEmailAndPassword, createUserWithEmailAndPassword } from 'firebase/auth'
import { formStates } from '../constants/helper-states'

// const fireauth = getAuth()

const killState = () => {
    return {
        type: `KILL_STATE`
    }
}

const saveAuth = (auth) => {
    return {
        type: `SAVE_AUTH`,
        auth
    }
}

export const saveAnonymousAuth = (auth) => {
    return {
        type: `SAVE_ANONYMOUS_AUTH`,
        auth
    }
}

export const removeAuth = () => {
    return {
        type: `REMOVE_AUTH`
    }
}

const saveAuthHandlerConfig = (code, action, email) => {
    return {
        type: `SAVE_AUTH_HANDLER`,
        code,
        action,
        email
    }
}

const saveAuthHandlerError = () => {
    return {
        type: 'AUTH_HANDLER_ERROR'
    }
}

export const clearAuthCode = () => {
    return {
        type: `REMOVE_AUTH_HANDLER`
    }
}

export const handleFirebaseAuthCode = (fireauth, code, action) => {
    return dispatch => {
        fireauth.verifyPasswordResetCode(code)
            .then(email => {
                dispatch(saveAuthHandlerConfig(code, action, email))
            })
            .catch(err => {
                dispatch(saveAuthHandlerError())
                console.log('got an error...', err)
            })
    }
}

export const anonymousSignIn = (fireauth, fetchingId) => {
    return dispatch => {
        fireauth.signInAnonymously()
            .then(() => {
                // dispatch(upsertFetching(fetchingId, fetchingStates.COMPLETE))
            })
            .catch(err => {
                console.log('error with anonymous')
            })
    }
}

export const registerInvitationWithEmailAndPass = (fireauth, email, password, formId) => {
    return dispatch => {
        fireauth.createUserWithEmailAndPassword(email, password)
            .then(() => {
                dispatch(upsertForm(formId, formStates.SUCCESS))
            })
            .catch(err => {
                // TODO: do we want to handle specific response codes?
                dispatch(upsertForm(formId, formStates.ERROR, `Error: ${err.message}`))
            })
    }
}

export const registerWithEmailAndPass = (fireauth, email, password, formId) => {
    return dispatch => {
        createUserWithEmailAndPassword(fireauth, email, password)
            .then(() => {
                dispatch(upsertForm(formId, formStates.SUCCESS))
            })
            .catch(err => {
                dispatch(upsertForm(formId, formStates.ERROR, `Error: ${err.message}`))
            })
    }
}

export const signInWithEmailAndPass = (fireauth, email, password, formId) => {
    return dispatch => {
        signInWithEmailAndPassword(fireauth, email, password)
            .then(() => {
                dispatch(upsertForm(formId, formStates.SUCCESS))
            })
            .catch(err => {
                dispatch(upsertForm(formId, formStates.ERROR, `Error: ${err.message}`))
            })
    }
}

export const connectToPusherWebsocket = () => {
    const auth = getAuth()
    return auth.currentUser.getIdToken()
        .then(token => {
            return {
                Authorization: `Bearer ${token}`
            }
        }).catch(err => {
            console.log('unable to get authorization info')
        })
}

// export const generatePasswordResetLink = (email, formId) => {
//     return dispatch => {
//         fireauth.sendPasswordResetEmail(email, actionCodeSettings)
//             .then(() => {
//                 dispatch(upsertForm(formId, formStates.SUCCESS))
//             })
//             .catch(err => {
//                 console.log(err)
//                 dispatch(upsertForm(formId, formStates.ERROR, `Error: ${err.message}`))
//             })
//     }
// }

export const resetPasswordWithCode = (fireauth, code, email, password, formId) => {
    return dispatch => {
        return fireauth.confirmPasswordReset(code, password)
            .then((response) => {
                fireauth.signInWithEmailAndPassword(email, password)
                    .then(() => {
                        dispatch(upsertForm(formId, formStates.SUCCESS))
                    })
                    .catch(err => {
                        console.log('in here with an error?')
                    })
            })
            .catch(err => {
                console.log('in here?')
                dispatch(upsertForm(formId, formStates.ERROR, `Error: ${err.message}`))
            })
    }
}

export const updateAuthPassword = (fireauth, formData, formId) => {
    return dispatch => {
        if (formData.newPassword !== formData.confirmPassword) {
            dispatch(upsertForm(formId, formStates.ERROR, 'New passwords do not match.'))
            return false
        }
        const fireUser = fireauth.currentUser
        const credentials = fireauth.EmailAuthProvider.credential(fireUser.email, formData.existingPassword)
        return fireUser.reauthenticateWithCredential(credentials)
            .then(() => {
                fireUser.updatePassword(formData.newPassword)
                    .then(() => {
                        dispatch(upsertForm(formId, formStates.SUCCESS))
                    })
                    .catch(err => {
                        dispatch(upsertForm(formId, formStates.ERROR, `Error: ${err.message}`))
                    })
            })
            .catch(err => {
                dispatch(upsertForm(formId, formStates.ERROR, `Error: ${err.message}`))
            })
    }
}

export const handleFirebaseAuth = (firebaseUser) => {
    return dispatch => {
        dispatch(saveAuth(firebaseUser))
    }
}

export const handleSignOut = () => {
    return dispatch => {
        const auth = getAuth()
        signOut(auth)
            .then(() => {
                dispatch(killState())
            })
            .catch(err => {
                // TODO: global message declaring we can't do that...
                console.log(err)
            })
    }
}
