import React from 'react'
import { connect } from 'react-redux'

import InputElement from '../../utils/input'
import SHSForm from '../../utils/shs-form'

import { clearForm } from '../../../actions/form'

import Details from './parameters/details'
import Respositories from './parameters/repositories'
import RequiredModules from './parameters/required-modules'
import Extras from './parameters/extras'
import Autoload from './parameters/autoload'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const COMPOSER_PARAMS = ['require', 'repositories', 'require-dev']

class D8toD9Converter extends React.Component {
    state = {
        composer: null,
        newComposer: null,
        initiateFileGeneration: false,
        hasGeneratedNewFile: false,
        componentCount: 0,
        updatedComponents: {}
    }

    submitFX = (formData, formId) => {
        let raw = formData.composer
        let parsed = true
        let cJSON = {}
        try {
            cJSON = JSON.parse(raw)
        } catch (e) {
            parsed = false
            this.props.dispatch(clearForm(formId))
            alert('Invalid JSON provided.')
        }
        if (parsed) {
            console.log(cJSON)
            let componentCount = 0
            COMPOSER_PARAMS.forEach(param => {
                if (param in cJSON) {
                    componentCount = componentCount + 1
                }
            })
            this.setState({ composer: cJSON, componentCount })
        }
    }

    entryForm = () => {
        if (this.state.composer) {
            return null
        }
        return (
            <SHSForm submitFX={this.submitFX} buttonLabel="Load Composer JSON">
                <InputElement
                    type="textarea"
                    label="Drupal 9 composer.json file"
                    id="composer"
                    placeholder="Copy the contents of your composer.json file here" />
            </SHSForm>
        )
    }

    generateNewComposerFile = () => {
        const stateComposer = JSON.parse(JSON.stringify(this.state.composer))
        const { updatedComponents } = this.state
        Object.keys(updatedComponents).forEach(param => {
            let paramData = updatedComponents[param]
            if (Array.isArray(paramData)) {
                paramData.forEach((item, idx) => {
                    if (item.action === 'UPDATE') {
                        stateComposer[param][idx].url = item.next
                    } else if (item.action === 'REMOVE') {
                        stateComposer[param].splice(idx, 1)
                    }
                })
            } else {
                Object.keys(paramData).forEach(key => {
                    if (paramData[key].action === 'UPDATE') {
                        stateComposer[param][key] = paramData[key].next
                    } else if (paramData[key].action === 'REMOVE') {
                        delete stateComposer[param][key]
                    } else if (paramData[key].action === 'MIGRATE') {
                        stateComposer[param][key] = 'MIGRATE ME TO ' + paramData[key].destination
                    }
                })
            }
        })
        this.setState({
            newComposer: stateComposer,
            hasGeneratedNewFile: true
        })
    }

    processFileGeneration = (param, data) => {
        let { updatedComponents } = this.state
        updatedComponents[param] = data
        this.setState({ updatedComponents })
        if (Object.keys(updatedComponents).length >= this.state.componentCount) {
            this.generateNewComposerFile()
        }
    }

    initialFileGeneration = () => {
        this.setState({ initiateFileGeneration: true })
    }

    resetPage = () => {
        this.setState({
            composer: null,
            initiateFileGeneration: false,
            hasGeneratedNewFile: false,
            componentCount: 0,
            updatedComponents: []
        })
    }

    renderLoadedState = () => {
        if (!this.state.composer) {
            return null
        }
        const { composer } = this.state
        if ('name' in composer) {
            return (
                <div className="notice">
                    <span>Loaded <strong>{composer.name}</strong> from text field.</span>
                    <span className="clickable close-icon" onClick={this.resetPage}>
                        Remove <FontAwesomeIcon icon="times" />
                    </span>
                </div>
            )
        }
        return <div>The composer JSON was loaded from the text field.</div>
    }

    renderComposerDetails = () => {
        if (!this.state.composer) {
            return null
        }
        const { composer, initiateFileGeneration } = this.state
        const commonProps = {
            onProcessFileGeneration: this.processFileGeneration,
            composer,
            initiateFileGeneration
        }
        let allDetails = []
        allDetails.push(<Details key="details-el" {...commonProps} />)
        if ('repositories' in composer) {
            allDetails.push(<Respositories key="repos-el" {...commonProps} />)
        }
        if ('require' in composer) {
            allDetails.push(<RequiredModules key="require-el" composerKey="require" {...commonProps} />)
        }
        if ('require-dev' in composer) {
            allDetails.push(<RequiredModules key="req-dev-el" composerKey="require-dev" {...commonProps} />)
        }
        if ('autoload' in composer) {
            allDetails.push(<Autoload key="autoload-el" composerKey="autoload" {...commonProps} />)
        }
        if ('extra' in composer) {
            allDetails.push(<Extras key="extra-el" composerKey="extra" {...commonProps} />)
        }
        return allDetails
    }

    renderGenerateButton = () => {
        const { composer, initiateFileGeneration, hasGeneratedNewFile } = this.state
        if (!composer || hasGeneratedNewFile) {
            return null
        } else if (!initiateFileGeneration) {
            return <span className="btn" onClick={() => this.setState({ initiateFileGeneration: true })}>Generate Updated Composer File</span>
        }
        return <span className="btn disabled">Generating File...</span>
    }

    copyNewComposer = () => {
        navigator.clipboard.writeText(JSON.stringify(this.state.newComposer, null, 4))
    }

    renderNewComposer = () => {
        if (this.state.newComposer && this.state.hasGeneratedNewFile) {
            return (
                <pre>
                    <p>
                        <span className="clickable clipboard-copy" onClick={this.copyNewComposer}>
                            <FontAwesomeIcon icon="clipboard" />
                            Copy composer.json contents
                        </span>
                    </p>
                    {JSON.stringify(this.state.newComposer, null, 4)}
                </pre>
            )
        }
        return null
    }

    render = () => {
        return (
            <>
                <h1>Convert a D8 composer file to D9</h1>
                <p>
                    Use this tool to check your D8 composer file.
                    You can get a status report on what needs to happen as well as to generate a D9 compatible file.
                </p>
                {this.entryForm()}
                {this.renderLoadedState()}
                {this.renderComposerDetails()}
                {this.renderGenerateButton()}
                {this.renderNewComposer()}
            </>
        )
    }
}

export default connect()(D8toD9Converter)
