import immutable from 'immutable'

const INITIAL_STATE = immutable.fromJS({})

const uiReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
    case `KILL_STATE`:
        return immutable.fromJS({})
    default:
        return state
    }
}

export default uiReducer
