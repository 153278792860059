import 'react-app-polyfill/stable'
import React from 'react'
import { render } from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux'
import { fromJS } from 'immutable'
import configureStore from './store/configure-store'
import reportWebVitals from './reportWebVitals'

import { initFirebase } from './firebase/firebase'

import './assets/scss/main.scss'
import App from './components/app'

const firebaseApp = initFirebase()

let store = configureStore(fromJS({}))

render(
    <Provider store={store}>
        <BrowserRouter>
            <App firebaseApp={firebaseApp} />
        </BrowserRouter>
    </Provider>, document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
