import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

import SHSForm from '../utils/shs-form'
import InputElement from '../utils/input'

import { registerWithEmailAndPass } from '../../actions/auth'

import { routes } from '../../constants/routes'

class Register extends React.Component {
    state = {}

    successFX = () => {
        // nothing for now
    }

    submitFX = (formData, formId) => {
        this.props.dispatch(registerWithEmailAndPass(this.props.fireauth, formData.email, formData.password, formId))
    }

    render = () => {
        return (
            <div className="box m-auto w-50">
                <h1>Register</h1>
                <SHSForm submitFX={this.submitFX} successFX={this.successFX} buttonLabel="Register">
                    <InputElement
                        type="email"
                        id="email"
                        placeholder="user@example.com"
                        label="Email Address"
                        errorMessage="Please provide a valid email address"
                        required />
                    <InputElement
                        type="password"
                        id="password"
                        placeholder="A secret word or phrase"
                        label="Password"
                        errorMessage="Password is required to be a minimum of 8 characters" />
                </SHSForm>
                <p>Already have an account? <Link to={routes.authLogin}>Login</Link></p>
            </div>
        )
    }
}

export default connect()(Register)
