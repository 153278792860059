import React from 'react'

// import { EXTRA_FIELDS, EXTRA_FIELDS_TWO } from '../../../../constants/composer'

class Extras extends React.Component {
    state = {
        showSummary: true,
        fields: {}
    }

    // TODO: there might be a way to do this by passing in strings with dot notation (i.e. 'drupal-scaffold.locations.web-root')
    setByDot = (obj, path, val) => {
        return path.split('.').reduce(function (prev, curr, idx, arr) {
            if (idx === (arr.length - 1) && prev) {
                prev[curr] = val
            }
            return prev ? prev[curr] : null
        }, obj)
    }

    // componentDidMount = () => {
    //     const { composer } = this.props
    //     let stateFields = {}

    //     EXTRA_FIELDS_TWO.forEach(field => {
    //         if (field.required) {
    //             let keys = field.field.split('.')
    //             console.log(keys)
    //         }
    //     })
    //     // check what the field status is...
    //     Object.keys(composer.extra).forEach(field => {
    //         if (field in EXTRA_FIELDS) {
    //             console.log('here with a field?')
    //             console.log(field)
    //             Object.keys(composer.extra[field]).forEach(param => {
    //                 if (param in EXTRA_FIELDS[field]) {
    //                     console.log(param)
    //                     console.log(stateFields)
    //                     console.log('here')
    //                 }
    //             })
    //         }
    //     })
    //     // now check that the required info is there
    //     Object.keys(EXTRA_FIELDS).forEach(field => {
    //         // first see if the field exists in the state obj
    //         if (EXTRA_FIELDS[field].required && !stateFields[field]) {
    //             stateFields[field] = {}
    //         }
    //         Object.keys(EXTRA_FIELDS[field]).forEach(param => {
    //             if (EXTRA_FIELDS[field][param].required && !composer.extra[field][param]) {
    //                 stateFields[field][param] = {}
    //                 EXTRA_FIELDS[field][param].keys.forEach((key, idx) => {
    //                     stateFields[field][param][key] = {
    //                         current: 'N/A',
    //                         next: EXTRA_FIELDS[field][param].vals[idx],
    //                         action: 'UPDATE'
    //                     }
    //                 })
    //             }
    //         })
    //     })
    //     this.setState({ stateFields })
    // }

    skipField = (key) => {
        let stateFields = this.state.stateFields
        console.log(stateFields[key])
        // stateFields[key].reset = stateFields[key].action
        // stateFields[key].action = 'SKIP'
        // this.setState({ stateFields: stateFields })
    }

    removeField = (key) => {
        let stateModules = this.state.modules
        stateModules[module].reset = stateModules[module].action
        stateModules[module].action = 'REMOVE'
        this.setState({ modules: stateModules })
    }

    resetField = (key) => {
        let stateModules = this.state.modules
        stateModules[module].action = stateModules[module].reset
        delete stateModules[module].reset
        this.setState({ modules: stateModules })
    }

    renderSummary = () => {
        return (
            <div>
                Extras listed here<br />
                <span onClick={() => this.setState({ showSummary: false })}>Show details</span>
            </div>
        )
    }

    renderFields = () => {
        const { stateFields } = this.state
        console.log(stateFields)
        let allExtras = []
        Object.keys(stateFields).forEach(field => {
            Object.keys(stateFields[field]).forEach(param => {
                Object.keys(stateFields[field][param]).forEach((key, idx) => {
                    allExtras.push(
                        <tr key={idx}>
                            <td>{field}.{param}.{key}</td>
                            <td>{stateFields[field][param][key].current}</td>
                            <td>{stateFields[field][param][key].next}</td>
                            <td>{stateFields[field][param][key].notes || ''}</td>
                            <td className="action">
                                {
                                    stateFields[field][param][key].action === 'SKIP' ?
                                        stateFields[field][param][key].reset && <span className="clickable" onClick={() => this.resetField(`${field}.${param}.${key}`)}>RESET</span> :
                                        <span className="clickable" onClick={() => this.skipField(`${field}.${param}.${key}`)}>SKIP</span>
                                }
                            </td>
                            <td className="action">
                                {
                                    stateFields[field][param][key].action === 'REMOVE' ?
                                        stateFields[field][param][key].reset && <span className="clickable" onClick={() => this.resetField(`${field}.${param}.${key}`)}>RESET</span> :
                                        <span className="clickable" onClick={() => this.removeField(`${field}.${param}.${key}`)}>REMOVE</span>
                                }
                            </td>
                        </tr>
                    )
                })
            })
        })
        return (
            <>
                <span key="toggle-require" onClick={() => this.setState({ showSummary: true })}>Hide details</span>
                <table className="table table-striped">
                    <thead>
                        <tr>
                            <th>Parameter</th>
                            <th>Installed</th>
                            <th>Recommended</th>
                            <th>Notes</th>
                            <th>&nbsp;</th>
                            <th>&nbsp;</th>
                        </tr>
                    </thead>
                    <tbody>
                        {allExtras}
                    </tbody>
                </table>
            </>
        )
    }

    render = () => {
        return (
            <div className="box mb-3">
                <h2>Extra Information</h2>
                <p>
                    Make sure that <strong>extra.drupal-scaffold.web-root</strong> is equal to <strong>'./'</strong>
                </p>
                {/* {
                    this.state.showSummary ?
                        this.renderSummary() :
                        this.renderFields()
                } */}
            </div>
        )
    }
}

export default Extras
