export const REQUIRE_PACKAGES = {
    'bower-asset/flexslider': {
        version: '^2.7'
    },
    'bower-asset/hammerjs': {
        version: '^2.0'
    },
    'bower-asset/mmenu': {
        version: '^8.5'
    },
    'bower-asset/photoswipe': {
        version: '^4.1'
    },
    'bower-asset/superfish': {
        version: '^1.7'
    },
    'composer/installers': {
        version: '^1.9',
        required: true
    },
    'cweagans/composer-patches': {
        version: '^1.7',
        required: true
    },
    'drupal/admin_toolbar': {
        version: '^3.0',
        required: true
    },
    'drupal/asset_injector': {
        version: '^2.8'
    },
    'drupal/auto_entitylabel': {
        version: '^3.0@beta'
    },
    'drupal/backup_migrate': {
        version: '^5.0'
    },
    'drupal/block_class': {
        version: '^1.3'
    },
    'drupal/cog': {
        version: '^1.15'
    },
    'drupal/config_ignore': {
        version: '^2.3',
        required: true
    },
    'drupal/core-composer-scaffold': {
        version: '^9.2',
        required: true
    },
    'drupal/core-project-message': {
        version: '^9.2',
        required: true
    },
    'drupal/core-recommended': {
        version: '^9.2',
        required: true
    },
    'drupal/ds': {
        version: '^3.13'
    },
    'drupal/entity_browser': {
        version: '^2.6',
        required: true
    },
    'drupal/entity_class_formatter': {
        version: '^1.3'
    },
    'drupal/environment_indicator': {
        version: '^4.0'
    },
    'drupal/field_group': {
        version: '^3.2'
    },
    'drupal/flexslider': {
        version: '^2.0'
    },
    'drupal/focal_point': {
        version: '^1.5'
    },
    'drupal/fontawesome': {
        version: '2.19',
        required: true
    },
    'drupal/fullcalendar_view': {
        version: '^5.1'
    },
    'drupal/google_tag': {
        version: '^1.4',
        required: true
    },
    'drupal/honeypot': {
        version: '^2.0',
        required: true
    },
    'drupal/inline_entity_form': {
        version: '^1.0@RC'
    },
    'drupal/libraries': {
        version: '^3.0@beta'
    },
    'drupal/linkit': {
        version: '^6.0@beta'
    },
    'drupal/menu_block': {
        version: '^1.7'
    },
    'drupal/metatag': {
        version: '^1.16',
        required: true
    },
    'drupal/module_filter': {
        version: '^3.2'
    },
    'drupal/paragraphs': {
        version: '^1.12',
        required: true
    },
    'drupal/pathauto': {
        version: '^1.8',
        required: true
    },
    'drupal/photoswipe': {
        version: '^3.0'
    },
    'drupal/redirect': {
        version: '^1.6',
        required: true
    },
    'drupal/replicate': {
        version: '^1.0'
    },
    'drupal/replicate_ui': {
        version: '^1.0'
    },
    'drupal/responsive_menu': {
        version: '^4.4'
    },
    'drupal/sa11y': {
        version: '^1.0',
        notes: 'See documentation. Extra steps req\'d.'
    },
    'drupal/video_embed_field': {
        version: '^2.4'
    },
    'drupal/viewfield': {
        version: '^3.0@beta'
    },
    'drupal/views_bulk_operations': {
        version: '^4.0'
    },
    'drupal/views_slideshow': {
        version: '^4.8'
    },
    'drupal/webform': {
        version: '^6.0',
        required: true
    },
    'drush/drush': {
        version: '^10.6',
        required: true
    },
    'oomphinc/composer-installers-extender': {
        version: '^2.0',
        required: true
    },
    'symfony/css-selector': {
        version: '^4.4'
    },
    'umass/umass_global_branding': {
        version: 'master',
        required: true
    },
    'umass/umass_site_branding': {
        version: 'master',
        required: true
    },
    'vlucas/phpdotenv': {
        version: '^5.3',
        required: true
    },
    'webmozart/path-util': {
        version: '^2.3'
    },
    'umass/umass_standard': {
        version: 'master'
    },
    'drupal/charts': {
        version: '^3.2'
    },
    'google/charts': {
        version: '45'
    },
    'bower-asset/highcharts': {
        version: '^9.3'
    },
    'drupal/simple_sitemap': {
        version: '^3.11'
    },
    'drupal/salesforce': {
        version: '^5.0'
    },
    'drupal/better_exposed_filters': {
        version: '^5.0'
    },
    'drupal/diff': {
        version: '^1.0'
    },
    'dealerdirect/phpcodesniffer-composer-installer': {
        version: '^0.7.1',
        migrate: 'require-dev'
    },
    'drupal/anchor_link': {
        version: '^2.5'
    },
    'drupal/coder': {
        version: '^8.3',
        migrate: 'require-dev'
    },
    'drupal/entity_clone': {
        version: '^1.0@beta'
    },
    'drupal/entityqueue': {
        version: '^1.2'
    },
    'drupal/iframe': {
        version: '^2.12'
    },
    'drupal/twig_tweak': {
        version: '^3.1'
    },
    'drupal/twig_xdebug': {
        version: '^1.2',
        migrate: 'require-dev'
    },
    'bower-asset/cropper': {
        version: '^4.1'
    },
    'bower-asset/imagesloaded': {
        version: '^4.1'
    },
    'bower-asset/jquery-simple-color': {
        version: '^1.2'
    },
    'bower-asset/jquery.cycle': {
        version: 'dev-master'
    },
    'bower-asset/jquery.hoverintent': {
        version: 'dev-gh-pages'
    },
    'bower-asset/masonry': {
        version: '^4.2'
    },
    'bower-asset/spectrum': {
        version: '^1.8'
    },
    'drupal/advanced_text_formatter': {
        version: '^2.1'
    },
    'drupal/bg_image_formatter': {
        version: '^1.15'
    },
    'drupal/captcha': {
        version: '^1.2'
    },
    'drupal/color_field': {
        version: '^2.5'
    },
    'drupal/conditional_fields': {
        version: '^4.0@alpha'
    },
    'drupal/crop': {
        version: '^2.1'
    },
    'drupal/dropzonejs': {
        version: '^2.5'
    },
    'drupal/editor_advanced_link': {
        version: '^2.0'
    },
    'drupal/editor_file': {
        version: '^1.6'
    },
    'drupal/entity': {
        version: '^1.2'
    },
    'drupal/entity_embed': {
        version: '^1.2'
    },
    'drupal/entity_reference_revisions': {
        version: '^1.9'
    },
    'drupal/entity_usage': {
        version: '^2.0@beta'
    },
    'drupal/feeds': {
        version: '^3.0@alpha'
    },
    'drupal/feeds_ex': {
        version: '^1.0@alpha'
    },
    'drupal/fontyourface': {
        version: '^3.6'
    },
    'drupal/form_placeholder': {
        version: '^1.0'
    },
    'drupal/ief_table_view_mode': {
        version: '^2.2'
    },
    'drupal/image_widget_crop': {
        version: '^2.3'
    },
    'drupal/imagecache_external': {
        version: '^3.0'
    },
    'drupal/linked_field': {
        version: '^1.3'
    },
    'drupal/mailchimp': {
        version: '^2.0'
    },
    'drupal/menu_breadcrumb': {
        version: '^1.16'
    },
    'drupal/quick_node_clone': {
        version: '^1.14'
    },
    'drupal/recaptcha': {
        version: '^3.0'
    },
    'drupal/simple_timeline': {
        version: '^1.2'
    },
    'drupal/time_range': {
        version: '^9.0'
    },
    'drupal/token': {
        version: '^1.9'
    },
    'drupal/views_field_view': {
        version: '^1.0@beta'
    },
    'drupal/views_infinite_scroll': {
        version: '^1.9'
    },
    'drupal/views_accordion': {
        version: '^2.0'
    },
    'drupal/menu_item_extras': {
        version: '^2.16'
    },
    'drupal/search_api': {
        version: '^1.21'
    },
    'drupal/search_api_autocomplete': {
        version: '^1.5'
    },
    'drupal/smart_date': {
        version: '^3.4'
    },
    'drupal/views_conditional': {
        version: '^2.0'
    },
    'drupal/views_jump_menu': {
        version: '^1.0@beta'
    },
    'drupal/ape': {
        version: '^1.5'
    },
    'drupal/content_moderation_notifications': {
        version: '^3.3'
    },
    'drupal/custom_search': {
        version: '^1.0@beta'
    },
    'drupal/date_recur': {
        version: '^3.0'
    },
    'drupal/date_recur_modular': {
        version: '^3.0'
    },
    'drupal/field_permissions': {
        version: '^1.1'
    },
    'drupal/image_effects': {
        version: '^3.1'
    },
    'drupal/scheduler': {
        version: '^1.4'
    },
    'drupal/views_bulk_edit': {
        version: '^2.6'
    },
    'umass/umass_classes': {
        version: 'dev-release'
    },
    'drupal/classy_paragraphs': {
        version: '^1.0@beta'
    },
    'drupal/single_page_site': {
        version: '^2.0'
    },
    'adci/full-name-parser': {
        version: '^0.2.4'
    },
    'drupal/bibcite': {
        version: '^2.0@beta'
    },
    'drupal/bibcite_crossref': {
        version: '1.x-dev@dev'
    },
    'drupal/blockgroup': {
        version: '^1.5'
    },
    'drupal/colorbox': {
        version: '^1.7'
    },
    'drupal/colorbox_simple_load': {
        version: '^2.0'
    },
    'drupal/ctools': {
        version: '^3.7'
    },
    'drupal/file_delete': {
        version: '^1.0'
    },
    'drupal/jsonapi_extras': {
        version: '^3.19'
    },
    'drupal/obfuscate_email': {
        version: '^1.3'
    },
    'drupal/publication_date': {
        version: '^2.0@beta'
    },
    'drupal/smart_trim': {
        version: '^1.3'
    },
    'drupal/taxonomy_multidelete_terms': {
        version: '^1.3'
    },
    'drupal/ultimate_cron': {
        version: '^2.0@alpha'
    },
    'drupal/views_block_filter_block': {
        version: '^1.0'
    },
    'jackmoore/colorbox': {
        version: '^1.6'
    },
    'npm-asset/a11y-accordion-tabs': {
        version: '^1.0'
    },
    'bower-asset/colorbox': {
        version: '^1.6'
    },
    'bower-asset/jquery': {
        version: '^3.6'
    },
    'drupal/a11y_paragraphs_tabs': {
        version: '^1.11'
    },
    'drupal/address': {
        version: '^1.9'
    },
    'drupal/address_map_link': {
        version: '^1.2'
    },
    'drupal/advagg': {
        version: '^4.1'
    },
    'drupal/dynamic_entity_reference': {
        version: '^1.12'
    },
    'drupal/formassembly': {
        version: '^1.3'
    },
    'drupal/key': {
        version: '^1.15'
    },
    'drupal/link_attributes': {
        version: '^1.11'
    },
    'drupal/mailsystem': {
        version: '^4.3'
    },
    'drupal/media_entity_file_replace': {
        version: '^1.0'
    },
    'drupal/mimemail': {
        version: '^1.0@alpha'
    },
    'drupal/queue_ui': {
        version: '^2.2'
    },
    'drupal/svg_image': {
        version: '^1.15'
    },
    'drupal/twig_field_value': {
        version: '^2.0'
    },
    'drupal/typed_data': {
        version: '^1.0@beta'
    },
    'drupal/console': {
        remove: true,
        notes: 'Install in require-dev'
    },
    'drupal/devel': {
        remove: true,
        notes: 'Install in require-dev'
    },
    'drupal/core': {
        remove: true,
        notes: 'Using core-recommended'
    },
    'drupal-composer/drupal-scaffold': {
        remove: true,
        notes: 'Using drupal/core-composer-scaffold'
    },
    'drupal/upgrade_status': {
        remove: true,
        notes: 'Not needed in D9'
    },
    'drupal/feeds_xpathparser': {
        remove: true,
        notes: 'Recommends drupal/feeds_ex'
    }
}

export const EXTRA_FIELDS_TWO = [
    {
        field: 'drupal-scaffold.locations.web-root',
        required: true,
        version: './'
    }
]

export const EXTRA_FIELDS = {
    'drupal-scaffold': {
        required: true,
        locations: {
            keys: ['web-root'],
            vals: ['./'],
            required: true
        }
    }
}

export const AUTOLOAD_FIELDS = {
    files: {
        version: 'load.environment.php',
        required: true
    }
}

export const REQUIRE_DEV_PACKAGES = {
    'drupal/core-dev': {
        version: '^9.2',
        required: true
    },
    'drupal/devel': {
        version: '^4.1',
        required: true
    },
    'drupal/stage_file_proxy': {
        version: '^1.1'
    },
    'dealerdirect/phpcodesniffer-composer-installer': {
        version: '^0.7.1'
    },
    'drupal/coder': {
        version: '^8.3'
    },
    'drupal/twig_xdebug': {
        version: '^1.2'
    },
    'drupal/console': {
        remove: true,
        notes: 'Managed by drush in D9'
    }
}

export const REPOSITORIES = [
    {
        name: 'drupal',
        type: 'composer',
        version: 'https://packages.drupal.org/8',
        required: true,
        matches: [
            'https://packages.drupal.org/8'
        ]
    },
    {
        name: 'packagist',
        type: 'composer',
        version: 'https://asset-packagist.org',
        required: true,
        matches: [
            'https://asset-packagist.org'
        ]
    },
    {
        name: 'UMass Global Branding',
        type: 'vcs',
        version: 'https://bitbucket.org/nsssystems/d8-umass-global-branding.git',
        matches: [
            'https://bitbucket.org/nsssystems/d8-umass-global-branding.git',
            'git@bitbucket.org:nsssystems/qs-mod-d8-umass-global-branding.git',
            'https://bitbucket.org/nsssystems/qs-mod-d8-umass-global-branding.git',
            'git@bitbucket.org:nsssystems/d8-umass-global-branding.git'
        ]
    },
    {
        name: 'UMass Site Branding',
        type: 'vcs',
        version: 'https://bitbucket.org/nsssystems/d8-umass-site-branding.git',
        matches: [
            'https://bitbucket.org/nsssystems/d8-umass-site-branding.git',
            'git@bitbucket.org:nsssystems/d8-umass-site-branding.git',
            'git@bitbucket.org:nsssystems/qs-mod-d8-umass-site-branding.git',
            'https://bitbucket.org/nsssystems/qs-mod-d8-umass-site-branding.git'
        ]
    },
    {
        name: 'UMass Standard Theme',
        type: 'vcs',
        version: 'https://bitbucket.org/nsssystems/qs-theme-d8-umass-standard.git',
        matches: [
            'git@bitbucket.org:nsssystems/qs-theme-d8-umass-standard.git',
            'https://bitbucket.org/nsssystems/qs-theme-d8-umass-standard.git'
        ]
    },
    {
        name: 'UMass Azure OpenID',
        type: 'vcs',
        version: 'https://bitbucket.org/nsssystems/umass_azure_openid.git',
        matches: [
            'https://bitbucket.org/nsssystems/umass_azure_openid.git'
        ]
    },
    {
        name: 'SHS D9 Base Theme',
        type: 'vcs',
        version: 'https://github.com/shadyhillstudios/d9-base-theme.git',
        matches: [
            'https://github.com/shadyhillstudios/d9-base-theme.git'
        ]
    }
]
