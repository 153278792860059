import React from 'react'
import { connect } from 'react-redux'
import { Routes, Route, Navigate } from 'react-router-dom'
import { withRouterProps } from './utils/with-router-props'
// import { withLocation } from './utils/with-location'

import { routes } from '../constants/routes'

import { getAuth, onAuthStateChanged } from 'firebase/auth'
import { handleFirebaseAuth, removeAuth, saveAnonymousAuth } from '../actions/auth'

// import AuthIndex from './auth/index'
// import AppRoute from './utils/app-route'

import AppIndex from './app/index'
import ConverterIndex from './app/converter/index'

import Home from './pages/home'
import NotFound from './pages/not-found'

// Auth components
import Login from './auth/login'
import Register from './auth/register'
import AuthRouter from './auth/router'
import CreateUser from './auth/create-user'

import '../assets/scss/app.scss'

import './utils/icons'

const ROUTER_PAGES = [routes.authLogin, routes.router, routes.authRegister, routes.authPasswordReset]

function AppRoute({ children, auth, location }) {
    let isAuthenticated = (!auth.isEmpty() && !auth.get('isAnonymous')) || false
    return isAuthenticated ? children : <Navigate to={routes.authLogin} state={{ from: location }} />
}

class App extends React.Component {
    state = {
        hasAuthRouted: false,
        fireauth: null,
        shouldRoute: false
    }

    triggerAuthRouter = () => {
        const fromState = this.props.location.state && this.props.location.state.from.pathname
            ? this.props.location.state.from.pathname
            : null
        let nextLocation = {
            pathname: routes.router,
            state: { from: { pathname: fromState } }
        }
        return <Navigate to={nextLocation.pathname} state={nextLocation.state} />
    }

    componentDidMount = () => {
        const { dispatch } = this.props
        const fireauth = getAuth(this.props.firebaseApp)
        this.setState({ fireauth })
        onAuthStateChanged(fireauth, firebaseUser => {
            if (firebaseUser) {
                if (firebaseUser.isAnonymous) {
                    dispatch(saveAnonymousAuth(fireauth, firebaseUser))
                } else {
                    dispatch(handleFirebaseAuth(firebaseUser))
                    const pathname = this.props.location.pathname
                    if (ROUTER_PAGES.indexOf(pathname) !== -1 ||
                        pathname.indexOf('/app/') !== -1 ||
                        pathname.indexOf('/admin/') !== -1 ||
                        pathname.indexOf('/invitation/') === 0
                    ) {
                        this.setState({ hasAuthRouted: true })
                        this.setState({ shouldRoute: true })
                    }
                }
            } else {
                dispatch(removeAuth())
                this.setState({ hasAuthRouted: false })
            }
        })
    }

    componentDidUpdate = (prevProps, prevState) => {
        if (!this.state.hasAuthRouted && !this.props.auth.isEmpty()) {
            if (prevProps.location.pathname !== this.props.location.pathname) {
                const pathname = this.props.location.pathname
                if (ROUTER_PAGES.indexOf(pathname) !== -1 ||
                    pathname.indexOf('/app/') !== -1 ||
                    pathname.indexOf('/admin/') !== -1 ||
                    pathname.indexOf('/invitation/') === 0
                ) {
                    this.setState({ hasAuthRouted: true })
                    this.setState({ shouldRoute: true })
                }
            }
        }
        if (this.state.hasAuthRouted && this.state.shouldRoute) {
            this.setState({ shouldRoute: false })
        }
    }

    render = () => {
        const { auth } = this.props
        if (this.state.shouldRoute) {
            return this.triggerAuthRouter()
        }
        return (
            <div className="container">
                <Routes>
                    <Route path={`${routes.appIndex}*`} element={<AppRoute auth={auth} location={this.props.location}><AppIndex /></AppRoute>} />
                    <Route path={routes.router} element={<AppRoute auth={auth} location={this.props.location}><AuthRouter /></AppRoute>} />
                    <Route path={routes.index} element={<Home />} />
                    <Route path={`${routes.converterIndex}*`} element={<ConverterIndex />} />
                    <Route path={routes.authLogin} element={<Login fireauth={this.state.fireauth} />} />
                    <Route path={routes.authRegister} element={<Register fireauth={this.state.fireauth} />} />
                    <Route path={routes.authCreateUser} element={<CreateUser />} />
                    <Route path="*" element={<NotFound />} />
                </Routes>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.get('auth')
    }
}

export default withRouterProps(connect(mapStateToProps)(App))
