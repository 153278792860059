import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { fetchApiDataIfNeeded } from '../../../actions/api'
import { apiResourceEndpoint, buildRoutePath, routes } from '../../../constants/routes'

class SitesDashboard extends React.Component {
    state = {}

    componentDidMount = () => {
        this.props.dispatch(fetchApiDataIfNeeded(apiResourceEndpoint('site', 'LIST')))
    }

    renderSites = () => {
        const { sites } = this.props
        if (!sites || sites.size === 0) {
            return null
        }
        let allSites = []
        sites.forEach(site => {
            allSites.push(
                <tr key={site.get('id')}>
                    <td>
                        <Link to={buildRoutePath(routes.sitesView, { siteId: site.get('id') })}>
                            {site.get('name')}
                        </Link>
                    </td>
                    <td>
                        {site.getIn(['_computed', 'statusDisplay'])}
                    </td>
                </tr>
            )
        })

        return (
            <div className="box">
                <table className="table">
                    <thead>
                        <tr>
                            <th>Site Name</th>
                            <th>Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        {allSites}
                    </tbody>
                </table>
            </div>
        )
    }

    render = () => {
        return (
            <div>
                <div className="create-btn">
                    <Link to={routes.sitesCreate}>
                        <FontAwesomeIcon icon="plus" /> Create Site
                    </Link>
                </div>
                {this.renderSites()}
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        sites: state.get('sites')
    }
}

export default connect(mapStateToProps)(SitesDashboard)
