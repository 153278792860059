import React from 'react'
import { connect } from 'react-redux'

import SHSForm from '../utils/shs-form'
import InputElement from '../utils/input'

import { callApi } from '../../actions/api'
import { apiResourceEndpoint, routes } from '../../constants/routes'

import * as MASKS from '../utils/input-masks'

class CreateUser extends React.Component {
    state = {}

    componentDidMount = () => {
        if (this.props.user && this.props.user.get('id') > 0) {
            this.props.history.push(routes.router)
        }
    }

    successFX = () => {
        this.props.history.push(routes.router)
    }

    submitFX = (formData, formId) => {
        // intercept the value of the acceptedTerms
        if (formData.acceptedTerms === 'on') {
            formData.acceptedTerms = true
        } else {
            formData.acceptedTerms = false
        }
        this.props.dispatch(callApi(apiResourceEndpoint('user', 'CREATE'), formData, formId))
    }

    render = () => {
        return (
            <div className="box m-auto w-50">
                <h1>User Information</h1>
                <SHSForm submitFX={this.submitFX} successFX={this.successFX} buttonLabel="Save">
                    <InputElement
                        type="text"
                        default=""
                        id="firstName"
                        label="First Name"
                        placeholder="Ty"
                        required />
                    <InputElement
                        type="text"
                        default=""
                        id="lastName"
                        label="Last Name"
                        placeholder="Cobb"
                        required />
                    <InputElement
                        type="phone"
                        default=""
                        id="phone"
                        label="Phone Number"
                        isFormatted={true}
                        formatMask={MASKS.phoneMask}
                        placeholder="(123) 456-7890" />
                    <InputElement
                        type="hidden"
                        id="checksum"
                        default={this.props.invitation ? this.props.invitation.get('checksum') : ''}
                        extraClass="d-none" />
                </SHSForm>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    const auth = state.get('auth')
    let invitation = null
    if (auth) {
        invitation = state.get('invitations').find(i => i.get('email') === auth.get('authEmail'))
    }
    return {
        user: state.get('user'),
        invitation
    }
}

export default connect(mapStateToProps)(CreateUser)
