import React from 'react'
import { Routes, Route } from 'react-router-dom'

import { routes, nestedRoutePath } from '../../../constants/routes'

import SitesDashboard from './dashboard'
import CreateSite from './create'
import ViewSite from './view'
import ViewInstance from './view-instance'

class SitesIndex extends React.Component {
    state = {}

    render = () => {
        return (
            <div>
                <Routes>
                    <Route index element={<SitesDashboard />} />
                    <Route path={nestedRoutePath(routes.sitesIndex, routes.sitesCreate)} element={<CreateSite />} />
                    <Route path={nestedRoutePath(routes.sitesIndex, routes.sitesView)} element={<ViewSite />} />
                    <Route path={nestedRoutePath(routes.sitesIndex, routes.sitesInstanceView)} element={<ViewInstance />} />
                </Routes>
            </div>
        )
    }
}

export default SitesIndex
