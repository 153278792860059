import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

import { routes } from '../../constants/routes'
// import { handleSignOut } from '../../actions/auth'

class HomePage extends React.Component {
    state = {}

    render = () => {
        return (
            <>
                <div className="home-page">
                    <img src="/shsites-white-logo.png" alt="Shady Hill Sites Logo" />
                    <div className="page-title">
                        <h1>Shady Hill Sites</h1>
                        <p className="px-4">Fast, flexible hosting solutions for Drupal and Laravel web applications.</p>
                    </div>
                    <Link to={routes.authLogin}>Login</Link>
                </div>
                <div className="footer">
                    &copy; Copyright 2022&nbsp;<a href="http://www.shadyhillstudios.com">Shady Hill Studios</a>
                </div>
            </>
        )
    }
}

export default connect()(HomePage)
