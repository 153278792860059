import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

import SHSForm from '../utils/shs-form'
import InputElement from '../utils/input'

import { signInWithEmailAndPass } from '../../actions/auth'
import { auth } from 'firebaseui'
import { getAuth, GoogleAuthProvider } from 'firebase/auth'

import { routes } from '../../constants/routes'

class Login extends React.Component {
    state = {}

    componentDidMount = () => {
        const fireauth = getAuth()
        const uiConfig = {
            signInFlow: 'popup',
            signInSuccessUrl: '/router',
            signInOptions: [
                {
                    provider: GoogleAuthProvider.PROVIDER_ID,
                    customParameters: {
                    }
                }
            ]
        }
        let ui = auth.AuthUI.getInstance() || new auth.AuthUI(fireauth)
        ui.start('#firebaseui-auth-container', uiConfig)
    }

    successFX = () => {
        // nothing for now
    }

    submitFX = (formData, formId) => {
        this.props.dispatch(signInWithEmailAndPass(this.props.fireauth, formData.email, formData.password, formId))
    }

    render = () => {
        return (
            <div className="container mt-5">
                <div className="box m-auto w-50">
                    <h1>Login</h1>
                    <SHSForm submitFX={this.submitFX} successFX={this.successFX} buttonLabel="Login">
                        <InputElement
                            type="email"
                            id="email"
                            placeholder="user@example.com"
                            label="Email Address"
                            errorMessage="Please provide a valid email address"
                            required />
                        <InputElement
                            type="password"
                            id="password"
                            placeholder="A secret word or phrase"
                            label="Password"
                            errorMessage="Password is required to be a minimum of 8 characters" />
                    </SHSForm>
                    <p>
                        {/* Don't have an account? <Link to={routes.authRegister}>Register Here</Link><br /> */}
                        Having trouble logging in? <Link to={routes.authPasswordHelp}>Get Login Help</Link>
                    </p>
                    <div id="firebaseui-auth-container" />
                </div>
            </div>
        )
    }
}

export default connect()(Login)
