import React from 'react'

class Autoload extends React.Component {
    state = {
        showSummary: true
    }

    renderSummary = () => {
        return (
            <div>
                Autoload listed here<br />
                <span onClick={() => this.setState({ showSummary: false })}>Show details</span>
            </div>
        )
    }

    renderFields = () => {
        return (
            <><span key="toggle-require" onClick={() => this.setState({ showSummary: true })}>Hide details</span></>
        )
    }

    render = () => {
        return (
            <div className="box mb-3">
                <h2>Autoload Information</h2>
                <p>
                    Make sure that <strong>autoload.files</strong> includes <strong>['load.environment.php']</strong>
                </p>
                {/* {
                    this.state.showSummary ?
                        this.renderSummary() :
                        this.renderFields()
                } */}
            </div>
        )
    }
}

export default Autoload
