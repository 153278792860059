import React from 'react'

class NotFoundPage extends React.Component {
    state = {}

    render = () => {
        return (
            <div>Page Not Found</div>
        )
    }
}

export default NotFoundPage
