import React from 'react'

import { REQUIRE_PACKAGES, REQUIRE_DEV_PACKAGES } from '../../../../constants/composer'

class RequiredModules extends React.Component {
    state = {
        showSummary: true,
        modules: {},
        constModules: this.props.composerKey === 'require' ? REQUIRE_PACKAGES : REQUIRE_DEV_PACKAGES
    }

    componentDidMount = () => {
        const { composer } = this.props
        let stateModules = {}
        Object.keys(composer[this.props.composerKey]).forEach(module => {
            if (module in this.state.constModules) {
                if ('remove' in this.state.constModules[module]) {
                    stateModules[module] = {
                        current: composer[this.props.composerKey][module],
                        next: 'DELETE',
                        action: 'REMOVE',
                        notes: this.state.constModules[module].notes || ''
                    }
                } else if ('migrate' in this.state.constModules[module]) {
                    stateModules[module] = {
                        current: composer[this.props.composerKey][module],
                        next: this.state.constModules[module].version,
                        action: 'MIGRATE',
                        destination: this.state.constModules[module].migrate,
                        notes: this.state.constModules[module].notes || ''
                    }
                } else {
                    stateModules[module] = {
                        current: composer[this.props.composerKey][module],
                        next: this.state.constModules[module].version,
                        action: composer[this.props.composerKey][module] === this.state.constModules[module].version ? 'SKIP' : 'UPDATE',
                        notes: this.state.constModules[module].notes || ''
                    }
                }
            }
        })
        // now make sure the required packages are in place
        Object.keys(this.state.constModules).forEach(module => {
            // console.log(this.state.constModules[module])
            if ('required' in this.state.constModules[module] && this.state.constModules[module].required === true) {
                if (!(module in stateModules)) {
                    stateModules[module] = {
                        current: 'N/A',
                        next: this.state.constModules[module].version,
                        action: 'UPDATE',
                        notes: 'Required package'
                    }
                }
            }
        })
        this.setState({ modules: stateModules })
    }

    componentDidUpdate = (prevProps) => {
        if (this.props.initiateFileGeneration && !prevProps.initiateFileGeneration) {
            this.props.onProcessFileGeneration(this.props.composerKey, this.state.modules)
        }
    }

    renderSummary = () => {
        // const { composer } = this.props
        const moduleCount = Object.keys(this.state.modules).length
        return (
            <div>
                {moduleCount} packages found in the require section<br />
                <span onClick={() => this.setState({ showSummary: false })}>Show package details</span>
            </div>
        )
    }

    colorVersion = (existing, updated) => {
        if (existing === updated) {
            return <span className="match">{updated}</span>
        }
        if (updated === 'DELETE') {
            return <span className="version">{updated}</span>
        }
        if (existing === 'N/A') {
            return <span className="new">{updated}</span>
        }
        let ePieces = existing.split('.', 2)
        let uPieces = updated.split('.', 2)
        if (ePieces.length < 2 || uPieces.length < 2) {
            return <span>{updated}</span>
        }
        let eLeading = parseInt(ePieces[0].replace(/\D/g, ''), 10)
        let uLeading = parseInt(uPieces[0].replace(/\D/g, ''), 10)
        // let uTrailing = parseInt(uPieces[1].replace(/\D/g, ''), 10)
        if (eLeading === uLeading) {
            // return <span><span className="match">{uPieces[0]}</span>.<span className="upgrade">{uPieces[1]}</span></span>
            return <span className="upgrade">{updated}</span>
        } else if (eLeading < uLeading) {
            return <span className="version">{updated}</span>
        }
        return <span className="upgrade">{eLeading}</span>
    }

    skipModule = (module) => {
        let stateModules = this.state.modules
        stateModules[module].reset = stateModules[module].action
        stateModules[module].action = 'SKIP'
        this.setState({ modules: stateModules })
    }

    removeModule = (module) => {
        let stateModules = this.state.modules
        stateModules[module].reset = stateModules[module].action
        stateModules[module].action = 'REMOVE'
        this.setState({ modules: stateModules })
    }

    resetModule = (module) => {
        let stateModules = this.state.modules
        stateModules[module].action = stateModules[module].reset
        delete stateModules[module].reset
        this.setState({ modules: stateModules })
    }

    renderPackages = () => {
        const { modules } = this.state
        let allPackages = []
        Object.keys(modules).forEach((module, idx) => {
            let nextValue = modules[module].next
            if (modules[module].action === 'REMOVE') {
                nextValue = 'DELETE'
            } else if (modules[module].action === 'SKIP') {
                nextValue = modules[module].current
            }
            allPackages.push(
                <tr key={idx}>
                    <td>{module}</td>
                    <td>{modules[module].current}</td>
                    <td>{this.colorVersion(modules[module].current, nextValue)}</td>
                    <td>{modules[module].notes}</td>
                    <td className="action">
                        {
                            modules[module].action === 'SKIP' ?
                                modules[module].reset && <span className="clickable" onClick={() => this.resetModule(module)}>RESET</span> :
                                <span className="clickable" onClick={() => this.skipModule(module)}>SKIP</span>
                        }
                    </td>
                    <td className="action">
                        {
                            modules[module].action === 'REMOVE' ?
                                modules[module].reset && <span className="clickable" onClick={() => this.resetModule(module)}>RESET</span> :
                                <span className="clickable" onClick={() => this.removeModule(module)}>REMOVE</span>
                        }
                    </td>
                </tr>
            )
        })
        return (
            <>
                <span key="toggle-require" onClick={() => this.setState({ showSummary: true })}>Hide package details</span>
                <table className="table table-striped w-100">
                    <thead>
                        <tr>
                            <th>Package Name</th>
                            <th>Installed</th>
                            <th>Recommended</th>
                            <th>Notes</th>
                            <th>&nbsp;</th>
                            <th>&nbsp;</th>
                        </tr>
                    </thead>
                    <tbody>{allPackages}</tbody>
                </table>
            </>
        )
    }

    render = () => {
        return (
            <div className="box mb-3">
                <h2>
                    {this.props.composerKey === 'require' ? 'Require Packages' : 'Require Dev Packages'}
                </h2>
                {
                    this.state.showSummary ?
                        this.renderSummary() :
                        this.renderPackages()
                }
            </div>
        )
    }
}

export default RequiredModules
