import React from 'react'
import { connect } from 'react-redux'
import { withRouterProps } from '../../utils/with-router-props'

import SwitchBranchManager from './repo/switch-branch'
import PullBranchManager from './repo/pull-branch'

import ClearDrushCache from './drush/clear-cache'
import ImportDrushConfig from './drush/import-config'

import ComposerInstall from './composer/install'

import { fetchApiDataIfNeeded } from '../../../actions/api'
import { apiResourceEndpoint } from '../../../constants/routes'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

class ViewInstance extends React.Component {
    state = {
        openCommands: []
    }

    componentDidMount = () => {
        this.props.dispatch(fetchApiDataIfNeeded(apiResourceEndpoint('sites-instance', 'VIEW', this.props.params.instanceId)))
    }

    sendCommand = (path) => {
        this.props.dispatch(fetchApiDataIfNeeded(apiResourceEndpoint('sites-instance', 'VIEW', this.props.params.instanceId, path), {}, true))
    }

    renderStatusLoader = () => {
        const { site, instance } = this.props
        return (
            <div className="box mt-4">
                <h2 className="mb-4">{site.get('name')}: {instance.get('name')}</h2>
                Status: {instance.getIn(['_computed', 'statusDisplay'])} <FontAwesomeIcon icon="spinner" spin />
            </div>
        )
    }

    toggleCommands = (command) => {
        let { openCommands } = this.state
        const idx = openCommands.indexOf(command)
        if (idx === -1) {
            openCommands.push(command)
        } else {
            openCommands.splice(idx)
        }
        this.setState({ openCommands })
    }

    render = () => {
        const { site, instance } = this.props
        if (!instance || !site) {
            return <div>No Instance Loaded</div>
        }
        if (instance.getIn(['_computed', 'statusDisplay']) !== 'Active') {
            return this.renderStatusLoader()
        }
        return (
            <div className="box mt-4">
                <h2 className="mb-4">{site.get('name')}: {instance.get('name')}</h2>
                <h3>Info</h3>
                <div className="mb-4">
                    Instance Type: {instance.getIn(['_computed', 'typeDisplay'])}<br />
                    IP: {instance.get('ip')}<br />
                    Domain:&nbsp;
                    <a href={`https://${instance.get('slug')}.shadyhillsites.com`} target="_blank" rel="noreferrer">
                        https://{instance.get('slug')}.shadyhillsites.com
                    </a>
                </div>
                <div className="mb-4">
                    <div className="infrastructure">
                        <div className="item">
                            <FontAwesomeIcon icon={['fab', 'drupal']} />
                            <span className="description">
                                {instance.getIn(['_computed', 'drupalVersionDisplay'])}
                            </span>
                        </div>
                        <div className="item">
                            <FontAwesomeIcon icon={['fab', 'php']} />
                            <span className="description">
                                {instance.getIn(['_computed', 'phpVersionDisplay'])}
                            </span>
                        </div>
                        <div className="item">
                            <FontAwesomeIcon icon="server" />
                            <span className="description">
                                {instance.getIn(['_computed', 'webserverVersionDisplay'])}
                            </span>
                        </div>
                        <div className="item">
                            <FontAwesomeIcon icon="database" />
                            <span className="description">
                                {instance.getIn(['_computed', 'databaseVersionDisplay'])}
                            </span>
                        </div>
                    </div>
                </div>
                {/* <h3>GitHub Tests</h3> */}
                {/* <div className="mb-4"> */}
                {/*     <p><span className="clickable" onClick={() => this.sendCommand('test-github')}>Test Github</span></p> */}
                {/* </div> */}
                <h3>Actions</h3>
                <div className="mb-4">
                    <div className="mb-3">
                        <div className="d-flex flex-direction-row align-items-center">
                            <span className="clickable" onClick={() => this.toggleCommands('REPO')}>Repository Commands</span>
                            <FontAwesomeIcon className="ml-2" icon={`${this.state.openCommands.indexOf('REPO') !== -1 ? 'chevron-down' : 'chevron-right'}`} />
                        </div>
                        { this.state.openCommands.indexOf('REPO') !== -1 &&
                            <div style={{ marginLeft: '1.5rem' }}>
                                <SwitchBranchManager siteInstance={instance} />
                                <PullBranchManager siteInstance={instance} />
                            </div>
                        }
                    </div>
                    <div className="mb-3">
                        <div className="d-flex flex-direction-row align-items-center">
                            <span className="clickable" onClick={() => this.toggleCommands('DRUSH')}>Drush Commands</span>
                            <FontAwesomeIcon icon={`${this.state.openCommands.indexOf('DRUSH') !== -1 ? 'chevron-down' : 'chevron-right'}`} />
                        </div>
                        { this.state.openCommands.indexOf('DRUSH') !== -1 &&
                            <div style={{ marginLeft: '1.5rem' }}>
                                <ClearDrushCache siteInstance={instance} />
                                <ImportDrushConfig siteInstance={instance} />
                            </div>
                        }
                    </div>
                    <div className="mb-3">
                        <div className="d-flex flex-direction-row align-items-center">
                            <span className="clickable" onClick={() => this.toggleCommands('COMPOSER')}>Composer Commands</span>
                            <FontAwesomeIcon icon={`${this.state.openCommands.indexOf('COMPOSER') !== -1 ? 'chevron-down' : 'chevron-right'}`} />
                        </div>
                        { this.state.openCommands.indexOf('COMPOSER') !== -1 &&
                            <div style={{ marginLeft: '1.5rem' }}>
                                <ComposerInstall siteInstance={instance} />
                            </div>
                        }
                    </div>
                    {/* <p>Review Backups <FontAwesomeIcon icon="chevron-right" /></p> */}
                    {/* <p><span className="clickable" onClick={() => this.sendCommand('add-known-host')}>Add Known Host</span></p>
                    <p><span className="clickable" onClick={() => this.sendCommand('configure-nginx')}>Configure Nginx</span></p>
                    <p><span className="clickable" onClick={() => this.sendCommand('clone-infrastructure')}>Clone Docker</span></p>
                    <p><span className="clickable" onClick={() => this.sendCommand('clone-drupal')}>Clone Drupal</span></p>
                    <p><span className="clickable" onClick={() => this.sendCommand('generate-repo')}>Generate Github Repo</span></p>
                    <p><span className="clickable" onClick={() => this.sendCommand('switch-github-origin')}>Switch Github Origin</span></p>
                    <p><span className="clickable" onClick={() => this.sendCommand('create-env')}>Config Env</span></p>
                    <p><span className="clickable" onClick={() => this.sendCommand('init-docker')}>Build Docker</span></p>
                    <p><span className="clickable" onClick={() => this.sendCommand('add-composer-auth')}>Add Composer Auth</span></p>
                    <p><span className="clickable" onClick={() => this.sendCommand('run-drupal-entrypoint')}>Run Drupal Entrypoint</span></p>
                    <p><span className="clickable" onClick={() => this.sendCommand('setup-ssl')}>Setup SSL</span></p> */}
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        site: state.get('sites').find(s => s.get('id') === Number(ownProps.params.siteId)),
        instance: state.get('sitesInstances').find(si => si.get('id') === Number(ownProps.params.instanceId))
    }
}

export default withRouterProps(connect(mapStateToProps)(ViewInstance))
