import React from 'react'
import { connect } from 'react-redux'
import shortid from 'shortid'

import { callApi } from '../../../../actions/api'
import { apiResourceEndpoint } from '../../../../constants/routes'

class ComposerInstall extends React.Component {
    state = {
        formId: shortid.generate()
    }

    dispatchCommand = () => {
        this.props.dispatch(callApi(apiResourceEndpoint('sites-instance', 'UPDATE', this.props.siteInstance.get('id'), 'composer-install'), {}, this.state.formId))
    }

    render = () => {
        return (
            <div className="my-3">
                <span className="clickable" onClick={this.dispatchCommand}>Run Composer Install</span>
            </div>
        )
    }
}

export default connect()(ComposerInstall)
