import React from 'react'

import { REPOSITORIES } from '../../../../constants/composer'

class Respositories extends React.Component {
    state = {
        showSummary: true,
        repos: []
    }

    componentDidMount = () => {
        const { composer } = this.props
        let stateRepos = []
        composer.repositories.forEach(repo => {
            REPOSITORIES.forEach(r => {
                if (r.matches.indexOf(repo.url) !== -1) {
                    stateRepos.push({
                        type: repo.type,
                        current: repo.url || 'N/A',
                        next: r.version,
                        action: repo.url === r.version ? 'SKIP' : 'UPDATE'
                    })
                }
            })
        })
        this.setState({ repos: stateRepos })
    }

    componentDidUpdate = (prevProps) => {
        if (this.props.initiateFileGeneration && !prevProps.initiateFileGeneration) {
            this.props.onProcessFileGeneration('repositories', this.state.repos)
        }
    }

    renderSummary = () => {
        const { composer } = this.props
        const repoCount = composer.repositories.length
        return (
            <div>
                {repoCount} repositories found in the repositories section<br />
                <span onClick={() => this.setState({ showSummary: false })}>Show repository details</span>
            </div>
        )
    }

    colorVersion = (existing, updated) => {
        if (existing === updated) {
            return <span className="match">{updated}</span>
        }
        if (updated === 'DELETE') {
            return <span className="version">{updated}</span>
        }
        return <span className="version">{updated}</span>
    }

    skipPackage = (idx) => {
        let stateRepos = this.state.repos
        stateRepos[idx].reset = stateRepos[idx].action
        stateRepos[idx].action = 'SKIP'
        this.setState({ repos: stateRepos })
    }

    removePackage = (idx) => {
        let stateRepos = this.state.repos
        stateRepos[idx].reset = stateRepos[idx].action
        stateRepos[idx].action = 'REMOVE'
        this.setState({ repos: stateRepos })
    }

    resetPackage = (idx) => {
        let stateRepos = this.state.repos
        stateRepos[idx].action = stateRepos[idx].reset
        delete stateRepos[idx].reset
        this.setState({ repos: stateRepos })
    }

    renderRepos = () => {
        const { repos } = this.state
        let allRepos = []
        repos.forEach((repo, idx) => {
            let nextValue = repo.next
            if (repo.action === 'REMOVE') {
                nextValue = 'DELETE'
            } else if (repo.action === 'SKIP') {
                nextValue = repo.current
            }
            allRepos.push(
                <tr className="repo" key={idx}>
                    <td>{repo.type}</td>
                    <td>{repo.current}</td>
                    <td>{this.colorVersion(repo.current, nextValue)}</td>
                    <td className="action">
                        {
                            repo.action === 'SKIP' ?
                                repo.reset && <span className="clickable" onClick={() => this.resetPackage(idx)}>RESET</span> :
                                <span className="clickable" onClick={() => this.skipPackage(idx)}>SKIP</span>
                        }
                    </td>
                    <td className="action">
                        {
                            repo.action === 'REMOVE' ?
                                repo.reset && <span className="clickable" onClick={() => this.resetPackage(idx)}>RESET</span> :
                                <span className="clickable" onClick={() => this.removePackage(idx)}>REMOVE</span>
                        }
                    </td>
                </tr>
            )
        })
        return (
            <>
                <span key="toggle-repos" onClick={() => this.setState({ showSummary: true })}>Hide repository details</span>
                <table className="table table-stripe">
                    <thead>
                        <tr>
                            <th>Type</th>
                            <th>Installed</th>
                            <th>Recommended</th>
                            <th>&nbsp;</th>
                            <th>&nbsp;</th>
                        </tr>
                    </thead>
                    <tbody>
                        {allRepos}
                    </tbody>
                </table>
            </>
        )
    }

    render = () => {
        return (
            <div className="box mb-3">
                <h2>Respositories</h2>
                {
                    this.state.showSummary ?
                        this.renderSummary() :
                        this.renderRepos()
                }
            </div>
        )
    }
}

export default Respositories
