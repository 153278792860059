import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { withRouterProps } from '../../utils/with-router-props'

import { fetchApiDataIfNeeded } from '../../../actions/api'
import { apiResourceEndpoint, buildRoutePath, routes } from '../../../constants/routes'

class ViewSite extends React.Component {
    state = {}

    componentDidMount = () => {
        this.props.dispatch(fetchApiDataIfNeeded(apiResourceEndpoint('site', 'VIEW', this.props.params.siteId)))
    }

    renderInstances = () => {
        const { instances } = this.props
        let allInstances = []
        instances.forEach(instance => {
            allInstances.push(
                <tr key={instance.get('id')}>
                    <td>
                        <Link to={buildRoutePath(routes.sitesInstanceView, { siteId: instance.get('siteId'), instanceId: instance.get('id') })}>
                            {instance.get('name')}
                        </Link>
                    </td>
                    <td>{instance.getIn(['_computed', 'typeDisplay'])}</td>
                    <td>{instance.get('ip')}</td>
                    <td>
                        <a href={`https://${instance.get('slug')}.shadyhillsites.com`}>
                            https://{instance.get('slug')}.shadyhillsites.com
                        </a>
                    </td>
                </tr>
            )
        })
        if (allInstances.length === 0) {
            return <span>No Instances</span>
        }
        return (
            <table className="table">
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Instance Type</th>
                        <th>IP</th>
                        <th>Site</th>
                    </tr>
                </thead>
                <tbody>
                    {allInstances}
                </tbody>
            </table>
        )
    }

    render = () => {
        const { site } = this.props
        if (!site) {
            return null
        }
        return (
            <div className="box mt-4">
                <h2>{site.get('name')}</h2>
                {this.renderInstances()}
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        site: state.get('sites').find(s => s.get('id') === Number(ownProps.params.siteId)),
        instances: state.get('sitesInstances').filter(si => si.get('siteId') === Number(ownProps.params.siteId))
    }
}

export default withRouterProps(connect(mapStateToProps)(ViewSite))
