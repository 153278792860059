import React from 'react'

const DETAIL_PARAMS = ['name', 'description', 'type', 'license']

class Details extends React.Component {
    state = {
        showSummary: true
    }

    renderSummary = () => {
        const { composer } = this.props
        let detailCount = 0
        DETAIL_PARAMS.forEach(param => {
            if (param in composer) {
                detailCount = detailCount + 1
            }
        })
        return (
            <div>
                {detailCount} info fields found in the composer file<br />
                <span onClick={() => this.setState({ showSummary: false })}>Show info details</span>
            </div>
        )
    }

    renderDetails = () => {
        const { composer } = this.props
        let allDetails = []
        DETAIL_PARAMS.forEach((param, idx) => {
            if (param in composer) {
                allDetails.push(
                    <tr key={idx}>
                        <td>{param}</td>
                        <td>{composer[param]}</td>
                    </tr>
                )
            }
        })
        return (
            <>
                <span key="toggle-details" onClick={() => this.setState({ showSummary: true })}>Hide info details</span>
                <table className="table table-striped">
                    <thead>
                        <tr>
                            <th>Paramter</th>
                            <th>Value</th>
                        </tr>
                    </thead>
                    <tbody>
                        {allDetails}
                    </tbody>
                </table>
            </>
        )
    }

    render = () => {
        return (
            <div className="box mb-3">
                <h2>Details</h2>
                {
                    this.state.showSummary ?
                        this.renderSummary() :
                        this.renderDetails()
                }
            </div>
        )
    }
}

export default Details
