import React from 'react'
import { connect } from 'react-redux'
import { Navigate } from 'react-router-dom'
import { withRouterProps } from '../utils/with-router-props'

import { routes, apiResourceEndpoint } from '../../constants/routes'
import { fetchApiDataIfNeeded } from '../../actions/api'

// import CreateUser from './create-user'
// import CreateAccount from './create-account'

class AuthRouter extends React.Component {
    state = {
        readyToRoute: false,
        onboardStatus: ''
    }

    componentDidMount = () => {
        // console.log('router loaded')
        // const route = apiResourceEndpoint('user', 'LIST', null, 'load-details')
        this.props.dispatch(fetchApiDataIfNeeded(apiResourceEndpoint('user', 'LIST', null, 'load-details')))
    }

    componentDidUpdate = (prevProps) => {
        const { auth, role, user, account } = this.props
        if (auth && auth.has('authEmail')) {
            if (user && user.has('id')) {
                const userId = user.get('id')
                if (userId === -1) {
                    this.setState({ onboardStatus: 'CREATE_USER' })
                } else if (role && role.has('name')) {
                    if (user.has('accountId') && user.get('accountId') > 0) {
                        if (account) {
                            this.setState({ readyToRoute: true })
                        }
                        // if (account && account.get('stripeCustomer')) {
                        //     if (account.hasIn(['_computed', 'subscriptionStatusValue']) && account.getIn(['_computed', 'subscriptionStatusValue']) === 'ACTIVE') {
                        //         this.setState({ readyToRoute: true })
                        //     } else {
                        //         this.setState({ onboardStatus: 'CREATE_SUBSCRIPTION' })
                        //     }
                        // } else {
                        //     console.log('need to upsert the stripe customer')
                        //     if (!this.state.stripeRequest) {
                        //         this.setState({ stripeRequest: true })
                        //         this.props.dispatch(callApi(apiResourceEndpoint('account', 'CREATE', null, 'stripe'), {}, -1))
                        //     }
                        // }
                    } else {
                        this.setState({ onboardStatus: 'CREATE_ACCOUNT' })
                    }
                }
            }
        }
    }

    // TODO: decide if this should go through a switch case here or send to an onboard handler...
    render = () => {
        const { location } = this.props
        if (this.state.readyToRoute) {
            if (location.state && location.state.from.pathname) {
                return <Navigate to={location.state.from.pathname} />
            }
            return <Navigate to={routes.appIndex} />
        }
        switch (this.state.onboardStatus) {
        case 'CREATE_USER':
            return <Navigate to={routes.authCreateUser} />
        case 'CREATE_ACCOUNT':
            return <Navigate to={routes.authCreateAccount} />
        // case 'CREATE_SUBSCRIPTION':
        //     return <Navigate to={routes.authCreateSubscription} />
        default:
            return <div>Router...</div>
        }
    }
}

const mapStateToProps = (state) => {
    return {
        account: state.get('account'),
        auth: state.get('auth'),
        role: state.get('role'),
        user: state.get('user')
    }
}

export default withRouterProps(connect(mapStateToProps)(AuthRouter))
