import { library } from '@fortawesome/fontawesome-svg-core'

import { faDrupal, faPhp } from '@fortawesome/free-brands-svg-icons'

import {
    faUsersCog, faSignOutAlt, faExchangeAlt, faPlus, faServer, faSearch, faTimes, faQuestionCircle, faBold, faItalic,
    faUnderline, faChevronDown, faChevronRight, faDatabase, faSpinner, faClipboard, faSitemap
} from '@fortawesome/free-solid-svg-icons'

// import { faChevronRight } from '@fortawesome/free-regular-svg-icons'

library.add(
    faUsersCog, faSignOutAlt, faExchangeAlt, faPlus, faServer, faSearch, faChevronRight, faTimes, faQuestionCircle, faBold, faItalic,
    faUnderline, faChevronDown, faDatabase, faSpinner, faClipboard, faSitemap,
    faDrupal, faPhp
)

