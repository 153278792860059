import React from 'react'

class AppDashboard extends React.Component {
    state = {}

    render = () => {
        return (
            <div className="box mt-4">
                <h1>Shady Hill Sites Dashboard</h1>
                <p>Welcome to the dashboard. Use the navigation above to find what you're looking for.</p>
            </div>
        )
    }
}

export default AppDashboard
