import { combineReducers } from 'redux-immutable'
import { fromJS } from 'immutable'

import apiCacheReducer from './api-cache'
import authCodeReducer from './auth-code'
import authReducer from './auth'
import formReducer from './form'
import genericReducer from './generic'
import uiReducer from './ui'

const BLANK_OBJ = fromJS({})
const BLANK_LIST = fromJS([])

const indexReducer = combineReducers({
    account: genericReducer('ACCOUNT', 'OBJECT', BLANK_OBJ),
    accountType: genericReducer('ACCOUNT_TYPE', 'OBJECT', BLANK_OBJ),
    apiCaches: apiCacheReducer,
    auth: authReducer,
    authCode: authCodeReducer,
    branches: genericReducer('BRANCHES', 'LIST', BLANK_LIST),
    configValues: genericReducer('CONFIG_VALUES', 'LIST', BLANK_LIST),
    forms: formReducer,
    invitations: genericReducer('INVITATIONS', 'LIST', BLANK_LIST),
    role: genericReducer('ROLE', 'OBJECT', BLANK_OBJ),
    sites: genericReducer('SITES', 'LIST', BLANK_LIST),
    sitesDomains: genericReducer('SITES_DOMAINS', 'LIST', BLANK_LIST),
    sitesInstances: genericReducer('SITES_INSTANCES', 'LIST', BLANK_LIST),
    sitesRepositories: genericReducer('SITES_REPOSITORIES', 'LIST', BLANK_LIST),
    ui: uiReducer,
    user: genericReducer('USER', 'OBJECT', BLANK_OBJ)
})

export default indexReducer
