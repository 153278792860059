import React from 'react'
import { connect } from 'react-redux'
import { Routes, Route, Link } from 'react-router-dom'

import { routes, nestedRoutePath } from '../../constants/routes'
import { handleSignOut, connectToPusherWebsocket } from '../../actions/auth'
import { manageApiData } from '../../actions/api'

import AppDashboard from './dashboard'
import SitesIndex from './sites/index'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import Pusher from 'pusher-js'

class AppIndex extends React.Component {
    state = {}

    async componentDidMount() {
        const { account } = this.props

        if (account) {
            // Pusher.logToConsole = true
            const auth = await connectToPusherWebsocket()

            let pusher = new Pusher('9db3334e73e75dbae7d5', {
                cluster: 'us2',
                forceTLS: true,
                authEndpoint: `${process.env.REACT_APP_API_URL}/pusher-socket/`,
                auth: {
                    headers: auth
                }
            })

            let channel = pusher.subscribe(`private-ws-${account.get('id')}`)
            channel.bind('ws.payload', data => {
                console.log('received data from websocket')
                console.log(data)
                this.props.dispatch(manageApiData(data))
            })
        }
    }

    renderHeader = () => {
        return (
            <div className="row">
                <div className="col shsites-header">
                    <div className="title">
                        <Link to={routes.appIndex}>Shady Hill Sites</Link>
                    </div>
                    <div className="links">
                        <Link to={routes.sitesIndex}>
                            <FontAwesomeIcon icon="sitemap" />
                            Sites
                        </Link>
                    </div>
                    <div className="account">
                        <span><FontAwesomeIcon icon="user-cog" title="Account Settings" /></span>
                        <span className="clickable" onClick={() => this.props.dispatch(handleSignOut())}><FontAwesomeIcon icon="sign-out-alt" title="Logout" /></span>
                    </div>
                </div>
            </div>
        )
    }

    render = () => {
        return (
            <>
                {this.renderHeader()}
                <Routes>
                    <Route index element={<AppDashboard />} />
                    <Route path={nestedRoutePath(routes.appIndex, routes.sitesIndex, true)} element={<SitesIndex />} />
                </Routes>
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        account: state.get('account')
    }
}

export default connect(mapStateToProps)(AppIndex)
