import { generatePath } from 'react-router-dom'
// import { compile } from 'path-to-regexp'

export const API_METHODS = { POST: 'POST', GET: 'GET', PUT: 'PUT', DELETE: 'DELETE' }

export const routes = {
    index: '/',
    router: '/router/',

    authLogin: '/login/',
    authLogout: '/logout/',
    authRegister: '/register/',
    authCreateUser: '/create-user/',
    authCreateAccount: '/create-account/',
    authPasswordHelp: '/password-help/',
    authPasswordReset: '/password-reset/',
    authHandler: '/auth-handler/',
    privacyPolicy: '/privacy-policy/',
    termsOfUse: '/terms-of-use/',
    invitation: '/invitation/:invitationIds/:checksum/',

    appIndex: '/app/',
    sitesIndex: '/app/sites/',
    sitesCreate: '/app/sites/create/',
    sitesView: '/app/sites/:siteId/',
    sitesInstanceView: '/app/sites/:siteId/instance/:instanceId',
    peopleIndex: '/app/people/',
    modalSample: '/:pagePath*/m/sample/',

    converterIndex: '/composer/',
    converterD8toD9: '/composer/d8-to-d9/',
    converterD8Terminal: '/composer/d8-terminal/',
    converterComposer2: '/composer/composer-2/'
}

export const apiResourceEndpoint = (resource, action, id = null, extraPath = '') => {
    let routeObj = {
        url: `/${resource}s/`,
        method: API_METHODS.GET
    }
    // adjust the route
    if (action === 'UPDATE' || action === 'VIEW' || action === 'DELETE') {
        routeObj.url = routeObj.url + `${id}/`
    }
    // adjust the medthod
    if (action === 'CREATE' || action === 'UPDATE') {
        routeObj.method = API_METHODS.POST
    } else if (action === 'DELETE') {
        routeObj.method = API_METHODS.DELETE
    }
    // do we need to append any extra stuff to the url
    if (extraPath !== '') {
        routeObj.url = routeObj.url + extraPath + '/'
    }
    return routeObj
}

export const apiRoutes = {
    webGet: { url: '/', method: API_METHODS.GET }
}

export const nestedRoutePath = (parent, route, isParent = false) => {
    let nestedRoute = route.substr(parent.length)
    if (isParent) {
        nestedRoute = nestedRoute + '*'
    }
    return nestedRoute
}

export const buildRoutePath = (route, params = {}) => {
    // const toPath = compile(route)
    // return toPath(params)
    return generatePath(route, params)
}

export const buildModalPath = (route, fullPagePath, params = {}) => {
    const modal = '/m/'
    const modalIndex = fullPagePath.indexOf(modal)
    let pagePath = fullPagePath
    if (modalIndex !== -1) {
        pagePath = fullPagePath.substr(0, modalIndex + 1)
    }
    let modalRoute = route.substr(route.indexOf(modal) + 1)
    // const toPath = compile(modalRoute)
    const generatedPath = generatePath(modalRoute, params)
    return `${pagePath}${generatedPath}${window.location.search}`
    // return `${pagePath}${toPath(params)}${window.location.search}`
}

export const buildCloseModalPath = (fullPagePath) => {
    const modal = '/m/'
    const modalIndex = fullPagePath.indexOf(modal)
    let pagePath = fullPagePath
    if (modalIndex !== -1) {
        pagePath = fullPagePath.substr(0, modalIndex + 1)
    }
    return pagePath
}
