import immutable from 'immutable'

const INITIAL_STATE = immutable.fromJS({})

const authReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
    case `SAVE_AUTH`:
        return state
            .set('emailVerified', action.auth.emailVerified)
            .set('authEmail', action.auth.email)
            .set('lastLoginDate', action.auth.metadata.lastSignInTime)
            .set('isAnonymous', action.auth.isAnonymous)
    case `SAVE_ANONYMOUS_AUTH`:
        return state
            .set('isAnonymous', action.auth.isAnonymous)
    case `REMOVE_AUTH`:
    case `KILL_STATE`:
        return immutable.fromJS({})
    default:
        return state
    }
}

export default authReducer
