import React from 'react'
import { Link, Route, Routes } from 'react-router-dom'

import { nestedRoutePath, routes } from '../../../constants/routes'

import D8toD9Converter from './d8-to-d9-converter'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

class ConverterIndex extends React.Component {
    state = {}

    renderHeader = () => {
        return (
            <div className="row">
                <div className="col shsites-header">
                    <div className="title">
                        <Link to={routes.index}>Shady Hill Sites</Link>
                    </div>
                    <div className="links">
                        <Link to={routes.converterIndex}>
                            <FontAwesomeIcon icon="exchange-alt" />
                            Composer Tool
                        </Link>
                    </div>
                    {/* <div className="account">
                        <span><FontAwesomeIcon icon="user-cog" title="Account Settings" /></span>
                        <span className="clickable" onClick={() => this.props.dispatch(handleSignOut())}><FontAwesomeIcon icon="sign-out-alt" title="Logout" /></span>
                    </div> */}
                </div>
            </div>
        )
    }

    render = () => {
        return (
            <>
                {this.renderHeader()}
                <div className="converter-wrapper">
                    <div className="converter-nav column">
                        <span className="header">
                            Conversion Options
                        </span>
                        <ul className="nav-items">
                            <li className="item">
                                <Link to={routes.converterD8toD9}>
                                    D8 Terminal to D9
                                </Link>
                            </li>
                            {/* <li className="item">
                                <Link to={routes.converterD8Terminal}>
                                    D8 to D8 Terminal
                                </Link>
                            </li>
                            <li className="item">
                                <Link to={routes.converterComposer2}>
                                    Composer 1 to 2
                                </Link>
                            </li> */}
                        </ul>
                    </div>
                    <div className="converter-content column">
                        <Routes>
                            <Route index element={<div><h1>Composer Converter</h1><p>Use this tool to convert D8 to D9.</p></div>}/>
                            <Route path={nestedRoutePath(routes.converterIndex, routes.converterD8toD9)} element={<D8toD9Converter />} />
                        </Routes>
                    </div>
                </div>
            </>
        )
    }
}

export default ConverterIndex
