import React from 'react'
import { connect } from 'react-redux'

import { callApi, fetchApiDataIfNeeded } from '../../../../actions/api'
import { apiResourceEndpoint } from '../../../../constants/routes'

import SHSForm from '../../../utils/shs-form'
import InputElement from '../../../utils/input'

class PullBranchManager extends React.Component {
    state = {
        branch: null
    }

    componentDidMount = () => {
        this.props.dispatch(fetchApiDataIfNeeded(apiResourceEndpoint('sites-instance', 'VIEW', this.props.siteInstance.get('id'), 'fetch-branches')))
    }

    refreshBranches = () => {
        this.props.dispatch(fetchApiDataIfNeeded(apiResourceEndpoint('sites-instance', 'VIEW', this.props.siteInstance.get('id'), 'fetch-branches'), {}, true))
    }

    handleChange = (id, branch) => {
        this.setState({ branch: branch })
    }

    submitFX = (formData, formId) => {
        this.props.dispatch(callApi(apiResourceEndpoint('sites-instance', 'UPDATE', this.props.siteInstance.get('id'), 'pull-branch'), formData, formId))
    }

    successFX = () => {
        // do nothing for now
        alert('The branch was checked out and pulled')
    }

    render = () => {
        const { siteInstance, branches } = this.props
        if (!siteInstance || !branches || branches.size === 0) {
            return <div>Loading branch info...</div>
        }
        const branchOptions = this.props.branches.map(opt => {
            return { value: opt.get('id'), label: opt.get('name') }
        })
        return (
            <div className="my-3">
                <SHSForm submitFX={this.submitFX} successFX={this.successFX} resetForm={true} showCancel={true} cancelButtonLabel="Refresh Branches" cancelAction={this.refreshBranches}>
                    <InputElement
                        type="valueSelect"
                        id="branch"
                        label="Select Branch to Pull"
                        default={this.state.branch}
                        options={branchOptions}
                        onChange={this.handleChange}
                        required />
                </SHSForm>
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        branches: state.get('branches').filter(b => b.get('siteInstanceId') === ownProps.siteInstance.get('id'))
    }
}

export default connect(mapStateToProps)(PullBranchManager)
